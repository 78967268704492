import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
//import { Storage } from '@ionic/storage-angular';
import { Storage } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { AlertesService } from './pages/alertes.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
   passuser;token;
  tipouser=this.alertService.tipouser;
nomuser=this.alertService.nomuser;
numuser=this.alertService.numuser;
login=this.alertService.login;
iduser=this.alertService.iduser;
codiuser=this.alertService.codiuser;

  constructor(private menu: MenuController,  private store: Storage,private alertService: AlertesService) {
    this.init();
  }
  
  async init() {
    const storage = new Storage({
      name: '_ionicstorage', storeName: '_arkria',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });
    this.store=storage;
    await this.store.create();
    
      if(this.alertService.nomuser) {this.nomuser=this.alertService.nomuser;} else {await this.store?.get('nom').then(data1=> {this.nomuser=data1;});}
      if(this.alertService.iduser) {this.iduser=this.alertService.iduser;} else { await this.store?.get('idusr').then(data5=> {this.iduser=data5;});}
      if(this.alertService.tipouser) {this.tipouser=this.alertService.tipouser;} else {await this.store?.get('tipousr').then(data2=> {this.tipouser=data2;});}
      if(this.alertService.numuser) {this.numuser=this.alertService.numuser;} else {await this.store?.get('numuser').then(data3=> {this.numuser=data3;});}
      if(this.alertService.codiuser) {this.codiuser=this.alertService.codiuser;} else {await this.store?.get('codiusr').then(data4=> {this.codiuser=data4;});}
  }

  closemenu(){this.menu.close('first');}
  closemenu2(){this.menu.close('second');}
  refresh() {location.reload();}
  cerrarsesion() {this.menu.close('first'); this.store.clear();location.reload();this.alertService.iduser=""; this.alertService.tipouser=""}
  cerrarsesion2() {this.menu.close('second'); this.store.clear();location.reload();}
  
}
