import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Drivers } from '@ionic/storage';
//import { Storage } from '@ionic/storage-angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FileUploadModule } from 'ng2-file-upload';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
//import { PipesPipe } from './pages/pipes/.pipe';
import * as moment from 'moment';


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule, FormsModule,
    ReactiveFormsModule,Ng2SearchPipeModule,FileUploadModule,FullCalendarModule,IonicStorageModule.forRoot({
      name: '_ionicstorage', storeName: '_arkria',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }), ServiceWorkerModule.register('/ngsw-worker.js', {
  enabled: environment.production,
  registrationStrategy: 'registerWhenStable:30000'
}),

],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },ToastController, Storage, Platform],
  bootstrap: [AppComponent],
})
export class AppModule {}
